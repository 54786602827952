import { FC } from 'react';
import { useParams } from 'react-router-dom';
import styles from './preview.module.scss';
import { WalletCardBasic, WalletStyle } from '../../../types/walletCard';
import useQrcodeController from '../../common/shareModal/useQrcodeController';
import i18n from '../../../translations/i18n';

interface AndroidPreviewProps {
    walletBasic: WalletCardBasic,
    coverPhoto: string
    profilePhoto: string
    walletStyle?: WalletStyle
    uuid: string,
    newProfilePhoto?: string
    newCoverPhoto?: string
}

const AndroidPreview: FC<AndroidPreviewProps> = ({
    walletBasic, coverPhoto, profilePhoto, walletStyle, uuid
}) => {
    const { qrCode } = useQrcodeController(uuid);

    const { id } = useParams();
    if (!id) return <></>;

    return (
        <>
            <div className={styles.containerAndroid} style={{backgroundColor: walletStyle?.cardBgColor}}>
                <div className={styles.top}>
                    <div className={styles.headerContainerAndroid} style={{backgroundColor: walletStyle?.cardBgColor}}>
                        {profilePhoto &&
                            <div className={styles.companyLogoWrapperAndroid}>
                                <img className={styles.companyLogoAndroid} src={profilePhoto} alt=""/>
                            </div>
                        }
                    </div>
                    <div className={styles.dividerAndroid}></div>
                    <div className={styles.infosContainer}>
                        <div className={styles.infoContainer}>
                            <div className={styles.info} style={{color: walletStyle?.cardTextColor}}>
                                {walletBasic?.fullname?.toUpperCase() || ''}
                            </div>
                        </div>
                        <div className={styles.companyPositionWrapper}>
                            <div className={styles.infoContainer}>
                                <span
                                    style={{color: walletStyle?.cardTextColor}}>{i18n.t('labels.company')}</span>
                                <div className={styles.info}
                                    style={{color: walletStyle?.cardTextColor}}>{walletBasic?.company || ''}</div>
                            </div>
                            <div className={styles.infoContainerPosition}>
                                <span
                                    style={{color: walletStyle?.cardTextColor}}>{i18n.t('labels.position')}</span>
                                <div className={styles.info}
                                    style={{color: walletStyle?.cardTextColor}}>{walletBasic?.position || ''}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.bottom}>
                    <div className={styles.qrcodeAndroid}>
                        {qrCode &&
                            <img src={qrCode} alt="QR code" className={styles.qrCodeImg}/>
                        }
                    </div>
                </div>
                <div className={styles.coverContainerAndroid}>
                    {coverPhoto
                        ? <img className={styles.coverAndroid} src={coverPhoto} alt=""/>
                        : <div className={styles.blankCover}></div>}
                </div>
            </div>
        </>
    );
};

export default AndroidPreview;