import { FC, useState } from 'react';
import styles from './profile-header.module.scss';
import { ReactComponent as LeftArrowIcon } from '../../../assets/icons/arrow/left-arrow.svg';
import { ReactComponent as AvatarIcon } from '../../../assets/icons/avatar.svg';
import { ReactComponent as EyePreviewIcon } from '../../../assets/icons/eyePreview.svg';
import { ReactComponent as ShareIcon } from '../../../assets/icons/share.svg';
import { ReactComponent as ThreeDotsIcon } from '../../../assets/icons/three-dots.svg';
import {NavLink, useNavigate} from 'react-router-dom';
import i18n from '../../../translations/i18n';
import {navigateTo} from '../../../constants/routes';
import { ShareModal } from '../../common/shareModal/ShareModal';
import Popover from '@mui/material/Popover';
import { ReactComponent as LockIcon } from '../../../assets/icons/lock.svg';
import { ReactComponent as TrashIcon } from '../../../assets/icons/trash.svg';
import { CustomSwitch } from '../../common/switch/CustomSwitch';
import Divider from '../../common/divider/Divider';
import { ACTIVE, DeleteModal, INACTIVE } from '../../../types/userCardProfile';
import { WarningModal } from '../../common/warningModal/WarningModal';
import { ReactComponent as WarningIcon } from '../../../assets/icons/warning.svg';
import {deleteCardProfileById, updateCardProfileStatus} from '../../../api/cardProfile';
import SaveButton from '../../common/button/SaveButton';

interface ProfileHeaderProps {
    isDirty: boolean
    setIsDirty: React.Dispatch<React.SetStateAction<boolean>>
    saveInfos: () => void
    loading: boolean
    isProfile?: boolean
    status?: string
    uuid: string
    fullName?: string
    cardProfileId: number
    profilePhoto?: string
    isSticky?: boolean
}

const initialDeleteModal = {
    isOpen: false,
    cardProfileId: null
};

const ProfileHeader: FC<ProfileHeaderProps> = ({
    isDirty, saveInfos, loading, isProfile, status, uuid, fullName, cardProfileId, profilePhoto, isSticky
}) => {
    const navigate = useNavigate();
    const isProfileActive = status === ACTIVE;

    const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
    const [checkedSwitch, setCheckedSwitch] = useState<boolean>(!isProfileActive);
    const [open, setOpen] = useState<boolean>(false);
    const [deleteModal, setDeleteModal] = useState<DeleteModal>(initialDeleteModal);
    
    const openModal = Boolean(anchorEl) && !deleteModal.isOpen;
    const id = openModal ? 'simple-popover' : undefined;

    const handleOpenDeleteModal = (cardProfileId: number) => setDeleteModal({isOpen: true, cardProfileId});
    const handleCloseDeleteModal = () => setDeleteModal(initialDeleteModal);

    const handleOnConfirmDeleteModal = () => {
        if (!deleteModal.cardProfileId) return;
        deleteCardProfileById(deleteModal.cardProfileId)
            .finally(() => {
                handleCloseDeleteModal();
                navigate(`${navigateTo.myCards}`);
            });
    };

    const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
        setAnchorEl((prev) => (prev ? null : event.currentTarget));
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    
    const handleShareModal = () => {
        setOpen(prevState => !prevState);
    };

    const onClose = () => {
        setOpen(false);
    };

    const handleSwitchChange = (e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        updateCardProfileStatus(cardProfileId, checked ? INACTIVE : ACTIVE);
        setCheckedSwitch(checked);
    };

    const onPreview = () => uuid && navigate(`${navigateTo.previewCardProfile}/${uuid}`);
    const toMyCardsPage = () => navigate(`${navigateTo.myCards}`);

    return (
        <div className={`${styles.headerContainer} ${isSticky ? styles.sticky : ''}`}>
            <nav className={styles.navContainer}>
                <div className={styles.leftLinks}>
                    <a className={styles.return} onClick={toMyCardsPage}>
                        <div><LeftArrowIcon/></div>
                        <div className={styles.avatar}>
                            {profilePhoto
                                ? <img className={styles.avatarImage} src={profilePhoto} alt="Avatar AndroidPreview" />
                                : <AvatarIcon className={styles.avatarImage} />
                            }
                        </div>
                    </a>
                    <span className={`${styles.hideMobile} ${styles.navLinks}`}>
                        <NavLinks cardProfileId={cardProfileId}/>
                    </span>
                </div>
                <div className={styles.rightLinks}>
                    {isProfile &&
                        <>
                            <div className={styles.link} onClick={onPreview}>
                                <EyePreviewIcon/>
                                <span className={styles.hideLargeScreen}>
                                    {i18n.t('common.previewCard')}
                                </span>
                            </div>
                            <div className={styles.link} onClick={handleShareModal}>
                                <ShareIcon/>
                                <span className={styles.hideLargeScreen}>
                                    {i18n.t('common.shareCard')}
                                </span>
                            </div>
                        </>
                    }
                    <SaveButton
                        isDirty={isDirty}
                        loading={loading}
                        saveInfos={saveInfos}
                        className={styles.hideMobile}
                    />
                    <div onClick={handleClick}>
                        <ThreeDotsIcon className={`${styles.dotsIcon} ${openModal && styles.menuActive}`}/>
                    </div>
                    <Popover
                        id={id}
                        open={openModal}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        PaperProps={{
                            className: styles.popoverStyle
                        }}
                    >
                        <div className={`${styles.item} ${styles.inactiveWrapper}`}>
                            <div className={styles.inactive}>
                                <div className={styles.iconWrapper}>
                                    <LockIcon/>
                                </div>
                                <div className={styles.text}>
                                    {i18n.t('common.setInactive')}
                                </div>
                                <div className={styles.switchWrapper}>
                                    <CustomSwitch
                                        checked={checkedSwitch}
                                        handleChange={handleSwitchChange}
                                    />
                                </div>
                            </div>
                            <div className={styles.subText}>
                                {i18n.t('myCards.setInactiveText2')}
                            </div>
                        </div>
                        <Divider/>
                        <div className={`${styles.item} ${styles.pointer}`}
                            onClick={() => handleOpenDeleteModal(cardProfileId)}>
                            <div className={styles.iconWrapper}>
                                <TrashIcon/>
                            </div>
                            <div className={`${styles.text} ${styles.deleteText}`}>
                                {i18n.t('common.deletePermanently')}
                            </div>
                        </div>
                    </Popover>
                    <ShareModal
                        isOpen={open}
                        uuid={uuid}
                        onClose={onClose}
                        fullName={fullName}
                    />
                </div>

                <WarningModal
                    isOpen={deleteModal.isOpen}
                    icon={<WarningIcon/>}
                    title={i18n.t('modal.areYouSure')}
                    text={i18n.t('modal.deleteText')}
                    confirmBtnText={i18n.t('common.deleteCard')}
                    cancelBtnText={i18n.t('common.cancel')}
                    onConfirm={handleOnConfirmDeleteModal}
                    onCancel={handleCloseDeleteModal}
                />
            </nav>
            <nav className={styles.navMobileContainer}>
                <NavLinks cardProfileId={cardProfileId} />
            </nav>
        </div>
    );
};

interface NavLinksProps {
    cardProfileId: number
}

const NavLinks: FC<NavLinksProps> = ({cardProfileId}) => {
    return (
        <>
            <NavLink
                to={`${navigateTo.profile}/${cardProfileId}`}
                className={({isActive}) => isActive ? styles.activeLink : styles.link}
            >
                {i18n.t('header.profilePage')}
            </NavLink>
            <NavLink
                to={`${navigateTo.walletCard}/${cardProfileId}`}
                className={({isActive}) => isActive ? styles.activeLink : styles.link}
            >
                {i18n.t('header.walletCard')}
            </NavLink>
        </>
    );
};

export default ProfileHeader;